import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";

function ComplyLayout({ children }) {
  return (
    <PageLayout>
      <MDBox p={1} width="100%" height="100vh" mx="auto">
            {children}
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the ComplyLayout
ComplyLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ComplyLayout;
