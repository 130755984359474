/* eslint-disable */
// prettier-ignore
"use client";

import cysrConfig, { process } from "layouts/cysr/config";
import MDButton from "components/MDButton";
import { Badge } from "layouts/cysr/components/ui/badge";
import { DataTableRowButton } from "./data-table-row-button-acknowledge";
import { format } from "date-fns"
export function DataTableExpandedContent({
  row
}) {
  return (
    <div className="w-full flex flex-col lg:flex-row gap-2">
      <div className="flex flex-col gap-4">

        {(row.original["leak_source"] && row.original["leak_source"] != "" && row.original["leak_source"].length > 0) && (<div className="flex flex-col lg:flex-row gap-1">
          <span className="w-16 font-light text-xs opacity-60">Data Source(s) / File Name(s):</span>
          <div className="max-w-3xl break-words lg:ml-2 lg:mt-2 font-medium text-base">
            {
              row.original["leak_source"].map((v, rii) =>
                <Badge key={rii} className="whitespace-nowrap" variant="outline">
                  {v}
                </Badge>
              )}
          </div>
        </div>)
        }
        <div className="flex flex-col lg:flex-row gap-1">
          <span className="w-16 font-light text-xs opacity-60">Password:</span>
          <span className="lg:ml-2 lg:mt-2 font-medium text-base">
            {row.original.password}
          </span>
        </div>
        {
          // dates s
        }
        <div className="flex flex-col lg:flex-row gap-1">
          <span className="w-16 font-light text-xs opacity-60">All findings dates:</span>

          <div className="max-w-3xl flex gap-1 flex-wrap break-words lg:ml-2 lg:mt-2 font-medium text-base">
            {
              row.original?.dates
              ?.sort((a, b) => {
                return new Date(b) - new Date(a);
              })
              ?.map((v, rii) =>
                <Badge key={rii} className="whitespace-nowrap" variant="outline">
                  {format(new Date(v), 'dd/MM/yyyy')}
                </Badge>
              )}
          </div>
          
        </div>
        {
          // dates e
        }
        {row.original.bucket && (<div className="flex flex-col lg:flex-row gap-1">
          <span className="w-16 font-light text-xs opacity-60">Bucket:</span>
          <span className="lg:ml-2 lg:mt-2 font-medium text-base">
            {row.original.bucket}
          </span>
        </div>)
        }
        {false && row.original.period && (<div className="flex flex-col lg:flex-row gap-1">
          <span className="w-16 font-light text-xs opacity-60">Period:</span>
          <span className="lg:ml-2 lg:mt-2 font-medium text-base">
            {row.original.period}
          </span>
        </div>)
        }
      </div>
      <div className="ml-auto flex flex-col lg:flex-row gap-2 text-xs">
        <DataTableRowButton rows={null} row={row} status={row.original.status} />
      </div>
      {false && (
        <div className="ml-auto flex flex-col lg:flex-row gap-2 text-xs">
          <MDButton style={cysrConfig().button_style} size="medium">
            <svg
              className="mr-2 h-4 w-4"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM7.50003 4C7.77617 4 8.00003 4.22386 8.00003 4.5V7H10.5C10.7762 7 11 7.22386 11 7.5C11 7.77614 10.7762 8 10.5 8H8.00003V10.5C8.00003 10.7761 7.77617 11 7.50003 11C7.22389 11 7.00003 10.7761 7.00003 10.5V8H4.50003C4.22389 8 4.00003 7.77614 4.00003 7.5C4.00003 7.22386 4.22389 7 4.50003 7H7.00003V4.5C7.00003 4.22386 7.22389 4 7.50003 4Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
            {process.env.NEXT_PUBLIC_STRING_FEATURE_800_FEATURE}
          </MDButton>
          <MDButton style={cysrConfig().button_style} size="medium">
            <svg
              className="mr-2 h-4 w-4"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM7.50003 4C7.77617 4 8.00003 4.22386 8.00003 4.5V7H10.5C10.7762 7 11 7.22386 11 7.5C11 7.77614 10.7762 8 10.5 8H8.00003V10.5C8.00003 10.7761 7.77617 11 7.50003 11C7.22389 11 7.00003 10.7761 7.00003 10.5V8H4.50003C4.22389 8 4.00003 7.77614 4.00003 7.5C4.00003 7.22386 4.22389 7 4.50003 7H7.00003V4.5C7.00003 4.22386 7.22389 4 7.50003 4Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
            {process.env.NEXT_PUBLIC_STRING_FEATURE_1000_FEATURE}{" "}
            {process.env.NEXT_PUBLIC_STRING_TERM_1100_PLURAL}
          </MDButton>
          <MDButton style={cysrConfig().button_style} size="medium">
            <svg
              className="mr-2 h-4 w-4"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM7.50003 4C7.77617 4 8.00003 4.22386 8.00003 4.5V7H10.5C10.7762 7 11 7.22386 11 7.5C11 7.77614 10.7762 8 10.5 8H8.00003V10.5C8.00003 10.7761 7.77617 11 7.50003 11C7.22389 11 7.00003 10.7761 7.00003 10.5V8H4.50003C4.22389 8 4.00003 7.77614 4.00003 7.5C4.00003 7.22386 4.22389 7 4.50003 7H7.00003V4.5C7.00003 4.22386 7.22389 4 7.50003 4Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
            {process.env.NEXT_PUBLIC_STRING_FEATURE_1000_FEATURE}{" "}
            {process.env.NEXT_PUBLIC_STRING_TERM_1200_SINGLE}
          </MDButton>
        </div>
      )}
    </div>
  );
}
