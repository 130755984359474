/* eslint-disable */

import { ClearSharp } from "@mui/icons-material";
import { ComplyInviteListUsersDeleteUserCompletely, ComplyInviteListUsersDeleteUserResource } from "./comply-invite-list-users-delete-fun";
import { Divider } from "@tremor/react";
import { useState } from "react";
import MDButton from "components/MDButton";
import cysrConfig from "layouts/cysr/config";

// prettier-ignore
export function UserProfiles({ accountUUID, data, callback }) {
    const [expandedCollabs, setExpandedCollabs] = useState([])
    return (
        Object.entries(data)?.map(([key, user]) => (
            <div key={key} className="flex flex-col gap-3 sm:gap-4">
                <div className="grow w-full">
                    {
                        Object.entries(user?.inviteduser_questionnaires)?.map(q => {
                            return <>
                                <div className="flex justify-between w-full gap-4 items-end">
                                    <div className="mt-3">
                                        <h3 className="font-medium text-black dark:text-white text-sm">
                                            {user.inviteduser_email}
                                        </h3>
                                        <p className="text-xs">ID: {q[0]}</p>
                                    </div>
                                    <MDButton style={cysrConfig().button_style} size="small" className="h-4"
                                        onClick={() => {
                                            if (expandedCollabs.indexOf(q[0]+user.inviteduser_email) > -1) {
                                                // Remove q[0] from expandedCollabs
                                                setExpandedCollabs(expandedCollabs.filter(collab => collab !== q[0]+user.inviteduser_email));
                                            } else {
                                                // Add q[0] to expandedCollabs
                                                setExpandedCollabs([...expandedCollabs, q[0]+user.inviteduser_email]);
                                            }
                                        }}

                                    >
                                        {expandedCollabs.indexOf(q[0]+user.inviteduser_email) > -1 ? (
                                            <svg
                                                className="h-4 w-4"
                                                width="15"
                                                height="15"
                                                viewBox="0 0 15 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M4 9H11L7.5 4.5L4 9Z" fill="currentColor"></path>
                                            </svg>
                                        ) : (
                                            <svg
                                                className="h-4 w-4"
                                                width="15"
                                                height="15"
                                                viewBox="0 0 15 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M4 6H11L7.5 10.5L4 6Z" fill="currentColor"></path>
                                            </svg>
                                        )}
                                    </MDButton>
                                </div>
                                {expandedCollabs.indexOf(q[0]+user.inviteduser_email) > -1 ?
                                    <>
                                        <Divider className="my-2" />
                                        <div className="mt-1 flex flex-col gap-2 text-sm w-full">
                                            {q[1]?.questionnaire_controls?.map(cId =>
                                                <div className="flex justify-between w-full items-center">
                                                    <span className="text-xs text-black dark:text-white">
                                                        {cId}
                                                    </span>
                                                    <button
                                                        onClick={() => ComplyInviteListUsersDeleteUserResource(accountUUID, user.inviteduser_email, cId, callback)}
                                                        className="text-red-600 inline-flex gap-1 px-2 items-center text-xs font-medium rounded-md border border-transparent focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
                                                    >
                                                        <ClearSharp fill="currentColor" width={12} height={12} />
                                                        Remove access to {cId}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        <Divider className="my-2" />

                                        <div className="flex justify-between w-full items-center">
                                            <span className="text-xs text-black dark:text-white">
                                                Invites for {user.inviteduser_email}
                                            </span>
                                            <button
                                                onClick={() => ComplyInviteListUsersDeleteUserCompletely(accountUUID, user.inviteduser_email, callback)}
                                                className="bg-red-600 text-white inline-flex gap-1 py-1 px-2 items-center text-xs font-medium rounded-md border border-transparent focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
                                            >
                                                <ClearSharp fill="currentColor" width={12} height={12} />
                                                Remove all access
                                            </button>
                                        </div>

                                    </>
                                    : null}
                            </>
                        })
                    }
                </div>
            </div>
        ))
    );
};