/* eslint-disable */
// prettier-ignore
import {
    Badge,
    ProgressBar,
    Button,
    Select,
    SelectItem,
    TextInput as Input,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    TableFoot,
    TableFooterCell,
} from '@tremor/react';
import { ArrowCircleRightSharp, CheckSharp, FlagSharp, KeyboardArrowDownSharp, KeyboardArrowUpSharp, StopSharp, UnfoldMoreSharp } from '@mui/icons-material';

import cysrConfig from "layouts/cysr/config";

import {
    filterFns,
    SortingState,
    ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues, //depends on getFacetedRowModel
    useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useVirtualizer } from '@tanstack/react-virtual'

import { parse, isValid, format } from "date-fns";

/*
{
    "framework_id": "b988bd20-643d-4950-b2ff-dbc977cb03d9",
    "framework_name": "BSI_Standard_2001",
    "framework_public": true,
    "framework_available": true,
    "framework_geography": "Universal",
    "framework_source": "BSI",
    "framework_version": "2022",
    "framework_url": "https_//www.bsi.bund.de/DE/Themen/UnternehmenundOrganisationen/StandardsundZertifizierung/ITGrundschutz/BSIStandards/BSIStandard2001ManagementsystemefuerInformationssicherheit/bsistandard2001managementsystemefuerinformationssicherheit_node.html",
    "framework_authoritative": "Standard_2001",
    "framework_strm": "N/A"
},
*/
const dateFormat = 'dd/MM/yyyy';
const getFormmatedDate = (date) => {
    const parsed = parse(date, dateFormat, new Date());
    if (isValid(parsed)) {
        return format(parsed, dateFormat);
    }
    return date;
};
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
export function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}) {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <Input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}
const multiSelectFilter = (
    row,
    columnId,
    filterValue,
) => {
    const rowValue = JSON.parse(row.getValue(columnId));
    return rowValue.indexOf(JSON.parse(filterValue)[0]) > -1
};
function processValue(input) {
    let string = ""
    try {
        input = JSON.parse(input)
    } catch (error) {

    }
    if (Array.isArray(input)) {
        input.forEach((element, index) => {
            string += `${index > 0 ? ", " : ""}${element}`;
        });
    } else {
        string = input;
    }
    return string
}
function Filter({ column }) {
    const { filterVariant } = column.columnDef.meta ?? {}
    if (filterVariant === 'none') {
        return null
    }

    const columnFilterValue = column.getFilterValue()

    const sortedUniqueValues = useMemo(
        () =>
            Array.from(column.getFacetedUniqueValues().keys())
                .sort()
                .slice(0, 5000),
        [column.getFacetedUniqueValues(), filterVariant]
    )
    let frameworksSet = [];
    //return false ;
    return filterVariant === 'select' ? (
        <div className="w-full flex items-center gap-2 flex-row max-w-lg">
            <Select className="w-full" id="selection" name="selection" onValueChange={(x) => column.setFilterValue(x)}
                value={columnFilterValue?.toString()}
            >
                <SelectItem key={'all'} value={''}>All</SelectItem>
                {sortedUniqueValues.map(value => {
                    return column.columnDef?.accessorKey === 'frameworks' ? (
                        JSON.parse(value).map(x => {
                            if (frameworksSet.indexOf(x) > -1) {
                                return null
                            } else {
                                frameworksSet.push(x)
                                return <SelectItem value={JSON.stringify([x])} key={JSON.stringify([x])}>{x}</SelectItem>
                            }
                        })
                    ) : (
                        column.columnDef?.accessorKey === 'approved' ?
                            <SelectItem value={value} key={value}>{value === true ? "Approved" : "Disapproved"}</SelectItem>
                            : <SelectItem value={value} key={value}>{processValue(value)}</SelectItem>
                    )
                })}
            </Select>
        </div>
    ) : (
        <>
            {/* Autocomplete suggestions from faceted values feature */}
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.map((value,i) => (
                    <option value={value} key={`${value}-${i}`} />
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '')}
                onChange={value => column.setFilterValue(value)}
                placeholder={""}
                className="w-36 border shadow rounded-tremor-default"
                list={column.id + 'list'}
            />
        </>
    )
}
export function CtrlTableQuestionnaires({ account_uuid, questionnaires, setQuestionnaires, isInvited }) {
    const [sorting, setSorting] = useState([])
    const [columnFilters, setColumnFilters] = useState([])
    const data = useMemo(
        () =>
            questionnaires?.map((item, i) => {
                return ({
                    account: {
                        // change here to get company id and name from questionnaire
                        account_uuid: account_uuid ? account_uuid : item.questionnaire_account_uuid,
                        account_name: isInvited ? item.questionnaire_companyname : "",
                    },
                    code: item.questionnaire_id,
                    frameworks: item.questionnaire_framework?.map(f => f.name),
                    name: item.questionnaire_name ? item.questionnaire_name : item.questionnaire_id,
                    completion: item.questionnaire_completion,
                    status: item.questionnaire_status,
                    approved: item.questionnaire_approved,
                    datecreated: item.questionnaire_datecreated,
                    datemodified: item.questionnaire_datemodified,
                    action: item,
                })
            }),
        [questionnaires]
    );

    const columns = useMemo(
        () => [
            /*
            {
                header: 'Order',
                accessorKey: 'order',
                enableSorting: true,
                meta: {
                    align: 'text-left',
                    filterVariant: 'none'
                },
                sortingFn: 'alphanumeric',
            },
            */
            ...(isInvited ? [{
                header: 'Company',
                accessorKey: 'company',
                enableSorting: true,
                meta: {
                    align: 'text-left',
                },
                sortingFn: 'alphanumeric',
                cell: ({ cell, row }) => {
                    return <div className='w-full max-w-xs overflow-hidden text-ellipsis inline-flex gap-1'>
                        {/* <a href={`/company/${row.original.account?.account_uuid}/overview`} target='_blank' title="Go to company"> */}
                            <strong className='text-black dark:text-white text-sm font-medium'>{row.original.account?.account_name}</strong>
                        {/* </a> */}
                    </div>
                },
            }] : []),
            {
                header: 'Name',
                accessorKey: 'name',
                enableSorting: true,
                meta: {
                    align: 'text-left',
                },
                sortingFn: 'alphanumeric',
                cell: ({ cell, row }) => {
                    return <div className='w-full max-w-xs overflow-auto inline-flex flex-col gap-1'>
                        <a href={
                            // isInvited ? `/cysr/invite-comply-questionnaire#${account_uuid}_${row.original.code}`
                            isInvited ? `/invite-comply-questionnaire?company=${row.original.account?.account_uuid}#${row.original.code}`
                                : `/company/${row.original.account?.account_uuid}/questionnaire#${row.original.code}`
                        } title="Go to questionnaire">
                            <strong className='text-black dark:text-white text-sm font-medium'>{row.original.name}</strong>
                        </a>
                        <div className='max-w-xs w-full gap-2 flex items-center'>
                            <ProgressBar value={(row.original.completion?.answered / row.original.completion?.total * 100)} color="teal" />
                            <p className="text-xs whitespace-nowrap text-tremor-default text-tremor-content dark:text-dark-tremor-content flex items-center justify-between">
                                <span>{(row.original.completion?.answered / row.original.completion?.total * 100).toFixed(0)}% &bull; <strong className='font-medium text-black dark:text-white'>{row.original.completion?.answered} of {row.original.completion?.total}</strong></span>
                            </p>
                        </div>
                    </div>
                },
            },
            {
                header: 'Frameworks',
                accessorKey: 'frameworks',
                accessorFn: row => JSON.stringify(row.frameworks),
                filterFn: 'multiSelectFilter',
                enableSorting: true,
                meta: {
                    align: 'text-left',
                    filterVariant: 'select'
                },
                sortingFn: (rowA, rowB, columnId) => {
                    const frameworksA = rowA.original.frameworks ? JSON.stringify(rowA.original.frameworks.sort()) : "Z";
                    const frameworksB = rowB.original.frameworks ? JSON.stringify(rowB.original.frameworks.sort()) : "Z";

                    //// Convert to lowercase for case-insensitive comparison
                    //const lowerCaseframeworksA = frameworksA.toLowerCase();
                    //const lowerCaseframeworksB = frameworksB.toLowerCase();

                    if (frameworksA < frameworksB) return -1;
                    if (frameworksA > frameworksB) return 1;
                    return 0;
                },
                cell: ({ cell, row }) => {
                    return (<div className='flex gap-2 w-full max-w-xs overflow-x-auto'>
                        {
                            row.original.frameworks?.map(f => <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{f}</Badge>)
                        }
                    </div>)
                },
            },
            /*
            {
                header: 'Progress',
                accessorKey: 'progress',
                cell: ({ cell, row }) => (<div className='max-w-xs w-full'>
                    <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content flex items-center justify-between">
                        <span>{(row.original.completion?.answered / row.original.completion?.total * 100).toFixed(0)}% &bull; <strong className='font-medium text-black dark:text-white'>{row.original.completion?.answered} of {row.original.completion?.total}</strong></span>
                    </p>
                    <ProgressBar value={(row.original.completion?.answered / row.original.completion?.total * 100)} color="teal" className="mt-3" />
                </div>),
                enableSorting: false,
                meta: {
                    align: 'text-left',
                    filterVariant: 'none'
                },
            },
            */
            {
                header: 'Status',
                accessorKey: 'status',
                cell: ({ cell, row }) => <div className="text-tremor-content dark:text-dark-tremor-content flex items-center space-x-2.5 truncate">
                    <div
                        className={'bg-' + {
                            "Deficient": "gray",
                            "DEFICIENT": "gray",
                            "In Place": "orange",
                            "Ready for Review": "amber",
                            "Monitored": "emerald"
                        }[row.original.status] + '-500 h-2.5 w-2.5 shrink-0 rounded-sm'}
                        aria-hidden={true}
                    />

                    <span className="truncate dark:text-dark-tremor-content-emphasis">
                        {row.original.status === "DEFICIENT" ? "Deficient" : row.original.status}
                    </span>
                </div>,
                enableSorting: true,
                meta: {
                    align: 'text-left',
                    filterVariant: 'select'
                },
                sortingFn: (rowA, rowB, columnId) => {
                    const statusA = rowA.original.status ? rowA.original.status : "Deficient";
                    const statusB = rowB.original.status ? rowB.original.status : "Deficient";

                    //// Convert to lowercase for case-insensitive comparison
                    //const lowerCaseStatusA = statusA.toLowerCase();
                    //const lowerCaseStatusB = statusB.toLowerCase();

                    if (statusA < statusB) return -1;
                    if (statusA > statusB) return 1;
                    return 0;
                }
            },
            {
                header: 'Approved',
                accessorKey: 'approved',
                enableSorting: true,
                cell: ({ cell, row }) => {
                    return row.original.approved === true
                        ? <div className='dark:text-dark-tremor-content-emphasis'><CheckSharp className='!h-6 !w-6 text-green-400' />Approved</div>
                        : <div className='dark:text-dark-tremor-content-emphasis'><StopSharp className='!h-6 !w-6 text-red-400' />Disapproved</div>
                },
                meta: {
                    align: 'text-left',
                    filterVariant: 'select'
                },
                sortingFn: 'alphanumeric'
            },
            {
                header: 'Date created',
                accessorKey: 'datecreated',
                cell: ({ cell, row }) => {
                    return getFormmatedDate(row.original.datecreated)
                },
                enableSorting: true,
                meta: {
                    align: 'text-left',
                    filterVariant: 'none'
                },
                sortingFn: 'alphanumeric'
            },
            {
                header: 'Date modified',
                accessorKey: 'datemodified',
                cell: ({ cell, row }) => {
                    return getFormmatedDate(row.original.datemodified)
                },
                enableSorting: true,
                meta: {
                    align: 'text-left',
                    filterVariant: 'none'
                },
                sortingFn: 'alphanumeric'
            },
            /*
            {
                header: 'Description',
                accessorKey: 'description',
                cell: ({ cell, row }) => (<div className='max-w-md'>
                    <p className='whitespace-normal line-clamp-2'>{row.original.description}</p>
                </div>),
                enableSorting: false,
                meta: {
                    align: 'text-left',
                }
            },
            {
                header: 'Target',
                accessorKey: 'target',
                enableSorting: true,
                meta: {
                    align: 'text-left',
                },
                sortingFn: 'alphanumeric'
            },
            */
            {
                header: 'Action',
                accessorKey: 'action',
                cell: ({ cell, row }) => {
                    return <a href={
                        // isInvited ? `/cysr/invite-comply-questionnaire#${account_uuid}_${row.original.code}` 
                        isInvited ? `/invite-comply-questionnaire?company=${row.original.account?.account_uuid}#${row.original.code}` : `/company/${row.original.account?.account_uuid}/questionnaire#${row.original.code}`} title="Go to questionnaire"><ArrowCircleRightSharp className='!h-6 !w-6 text-turquoise-400' /></a>
                },
                enableSorting: false,
                meta: {
                    align: 'text-right',
                    filterVariant: 'none'
                },
            },
        ],
        [questionnaires]
    );

    // https://blocks.tremor.so/blocks/table-actions
    const table = useReactTable({
        /*
        {
            workspace: 'sales_by_day_api',
            owner: 'John Doe',
            status: 'live',
            costs: '$3,509.00',
            region: 'US-West 1',
            capacity: '99%',
            lastEdited: '23/09/2023 13:00',
        }
        */
        data: data,
        /*
        Code
        Status
        Maturity
        Description
        Target
        Action
        */
        columns: columns,
        filterFns: {
            'multiSelectFilter': multiSelectFilter,            //filter function added
        },
        initialState: {
            sorting: [
                {
                    id: 'order',
                    desc: false, // sort in descending order by default
                },
            ],
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        // getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),

        getFacetedRowModel: getFacetedRowModel(), //if you need a list of values for a column (other faceted row models depend on this one)
        getFacetedUniqueValues: getFacetedUniqueValues(), //if you need a list of unique values

        state: {
            sorting, columnFilters
        }
    });

    const { rows } = table.getRowModel()

    const parentRef = useRef(null)

    const virtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 73,
        overscan: 8,
    })

    return (

        <div
            className='mt-8'
            style={{
                paddingTop: 87,
                position: 'relative',
            }}>
            <div
                ref={parentRef}
                style={{
                    height: `60vh`,
                    minHeight: `720px`,
                    overflow: 'auto',
                }}
            >
                <div
                    style={{
                        height: `${virtualizer.getTotalSize()}px`,
                        width: '100%',
                    }}
                >
                    <table className='tremor-Table-table w-full text-tremor-default text-tremor-content dark:text-dark-tremor-content'>
                        <TableHead className='absolute top-0 w-full'>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow
                                    key={headerGroup.id}
                                    className="border-b border-tremor-border dark:border-dark-tremor-border w-full flex items-end gap-2"
                                >
                                    {headerGroup.headers.map((header) => header?.column?.columnDef?.accessorKey === "action" ? null : (
                                        <div style={header?.column?.columnDef?.accessorKey === "frameworks" ? {
                                            minWidth: "320px"
                                        } : null} className="inline-flex flex-col w-full">
                                            {header.column.getCanFilter() ? (
                                                <div>
                                                    <Filter column={header.column} />
                                                </div>
                                            ) : null}
                                            <TableHeaderCell
                                                key={header.id}
                                                onClick={header.column.getToggleSortingHandler()}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        header.column.getToggleSortingHandler()(event);
                                                    }
                                                }}
                                                className={classNames(
                                                    header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : '',
                                                    'px-0.5 py-1.5',
                                                    header?.column?.columnDef?.accessorKey === 'frameworks' ? "w-full" : null
                                                )}
                                                style={{
                                                    verticalAlign: 'top'
                                                }}
                                                tabIndex={header.column.getCanSort() ? 0 : -1}
                                                aria-sort={header.column.getIsSorted()}
                                            >
                                                <div className='flex flex-col'>
                                                    <div
                                                        className={classNames(
                                                            header.column.columnDef.enableSorting === true
                                                                ? 'flex items-center justify-between gap-2 hover:bg-tremor-background-muted hover:dark:bg-dark-tremor-background-muted'
                                                                : header.column.columnDef.meta.align,
                                                            ' rounded-tremor-default px-3 py-1.5',
                                                        )}
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext(),
                                                        )}
                                                        {header.column.columnDef.meta.filterVariant && header.column.columnDef.meta.filterVariant === 'none'
                                                            ? null
                                                            : header.column.columnDef?.accessorKey === "frameworks"
                                                                ? null : ` (${header.column.getFacetedUniqueValues().size})`
                                                        }
                                                        {header.column.getCanSort() ? (
                                                            header.column.getIsSorted() === 'asc' ? (
                                                                <KeyboardArrowUpSharp
                                                                    className="h-6 w-6 text-tremor-content-strong dark:text-dark-tremor-content-strong"
                                                                    aria-hidden={true}
                                                                />
                                                            ) : header.column.getIsSorted() === 'desc' ? (
                                                                <KeyboardArrowDownSharp
                                                                    className="h-6 w-6 text-tremor-content-strong dark:text-dark-tremor-content-strong"
                                                                    aria-hidden={true}
                                                                />
                                                            ) : (
                                                                <UnfoldMoreSharp
                                                                    className="h-6 w-6 text-tremor-content dark:text-dark-tremor-content"
                                                                    aria-hidden={true}
                                                                />
                                                            )
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </TableHeaderCell>
                                        </div>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <tbody className='tremor-TableBody-root align-top'>{table.getRowModel().rows?.length ? (
                            // table.getRowModel().rows.map((row) => (
                            virtualizer.getVirtualItems().map((virtualRow, index) => {
                                const row = rows[virtualRow.index]
                                return (
                                    <TableRow key={row.id}
                                        className='relative'
                                        style={{
                                            height: `${virtualRow.size}px`,
                                            transform: `translateY(${virtualRow.start - index * virtualRow.size
                                                }px)`,
                                        }}>
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell
                                                key={cell.id}
                                                className={classNames(cell.column.columnDef.meta.align)}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                )
                            }
                            )
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="absolute opacity-25 inset-x-0 bottom-0 -mb-1 h-14 rounded-b-lg bg-gradient-to-t from-tremor-background via-tremor-background to-transparent dark:from-gray-950 dark:via-gray-950 dark:to-transparent" />
            <p className="text-zinc-500 dark:text-zinc-300 text-sm pt-4 px-4 pb-2">
                Viewing {table.getSortedRowModel()?.flatRows?.length} of {questionnaires?.length} questionnaires
            </p>
        </div>
    );
    /*
    return (
        <><Table className="mt-5">
            <TableHead>
                <TableRow>
                    {
                        // <TableHeaderCell>ID</TableHeaderCell>
                    }
                    <TableHeaderCell>Name and Frameworks</TableHeaderCell>
                    <TableHeaderCell>Progress</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell>Approved</TableHeaderCell>
                    <TableHeaderCell>Date Created</TableHeaderCell>
                    <TableHeaderCell>Date modified</TableHeaderCell>
                    {
                        // <TableHeaderCell>Status</TableHeaderCell>
                    }
                    <TableHeaderCell>Actions</TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {questionnaires
                    .sort((a, b) => {
                        if (!a.questionnaire_name) return 1;
                        if (!b.questionnaire_name) return -1;
                        return a.questionnaire_name.localeCompare(b.questionnaire_name);
                    })
                    .map((item) => (
                        <TableRow key={item.questionnaire_id}>
                            {
                                // <TableCell>{item.questionnaire_id}</TableCell>
                            }
                            <TableCell className='align-top'>
                                <a href={`/company/${account_uuid}/questionnaire#${item.questionnaire_id}`} title="Go to questionnaire">
                                    {
                                        item.questionnaire_name && item.questionnaire_name.length > 0
                                            ?
                                            <strong className='text-black dark:text-white text-base font-medium'>
                                                {item.questionnaire_name}
                                            </strong>
                                            : item.questionnaire_id
                                    }
                                </a>
                                <div className='flex gap-2 max-w-xs overflow-x-auto mb-4'>
                                    {
                                        item.questionnaire_framework?.map(f => <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{f.name}</Badge>)
                                    }
                                </div>
                            </TableCell>
                            <TableCell className='align-top'>
                                <div className='max-w-xs w-full'>
                                    <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content flex items-center justify-between">
                                        <span>{(item.questionnaire_completion?.answered / item.questionnaire_completion?.total * 100).toFixed(0)}% &bull; <strong className='font-medium text-black dark:text-white'>{item.questionnaire_completion?.answered} of {item.questionnaire_completion?.total}</strong></span>
                                    </p>
                                    <ProgressBar value={(item.questionnaire_completion?.answered / item.questionnaire_completion?.total * 100)} color="teal" className="mt-3" />
                                </div>
                            </TableCell>
                            <TableCell className='align-top'>
                                <div className="text-tremor-content dark:text-dark-tremor-content flex items-center space-x-2.5 truncate">
                                    <div
                                        className={'bg-' + {
                                            "Deficient": "gray",
                                            "DEFICIENT": "gray",
                                            "In Place": "orange",
                                            "Ready for Review": "amber",
                                            "Monitored": "emerald"
                                        }[item.questionnaire_status] + '-500 h-2.5 w-2.5 shrink-0 rounded-sm'}
                                        aria-hidden={true}
                                    />

                                    <span className="truncate dark:text-dark-tremor-content-emphasis">
                                        {item.questionnaire_status === "DEFICIENT" ? "Deficient" : item.questionnaire_status}
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell className='align-top'>
                                {item.questionnaire_approved
                                    ? <div className='dark:text-dark-tremor-content-emphasis'><CheckSharp className='!h-6 !w-6 text-green-400' />Approved</div>
                                    : <div className='dark:text-dark-tremor-content-emphasis'><StopSharp className='!h-6 !w-6 text-red-400' />Disapproved</div>
                                }
                            </TableCell>
                            <TableCell className='align-top'>
                                {item.questionnaire_datecreated}
                            </TableCell>
                            <TableCell className='align-top'>
                                {item.questionnaire_datemodified}
                            </TableCell>
                            <TableCell className='align-top'>
                                <div className='flex gap-2'>
                                    {
                                        false && (
                                            <button onClick={() => {
                                                const newApprovedVal = !item.questionnaire_approved
                                                fetch(`${cysrConfig().ctrl_api_base_url}company/${account_uuid}/questionnaire/${item.questionnaire_id}`, {
                                                    body: JSON.stringify({
                                                        approved: newApprovedVal
                                                    }),
                                                    headers: {
                                                        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                        "content-type": "application/json",
                                                    },
                                                    method: "PATCH"
                                                })
                                                    .then((response) => response.json())
                                                    .then((getControlResponse) => {
                                                        let newQs = [...questionnaires];
                                                        newQs[newQs.findIndex(q => q.questionnaire_id === item.questionnaire_id)].questionnaire_approved = newApprovedVal;
                                                        setQuestionnaires(newQs)
                                                    })
                                                    .catch((err) => {
                                                        console.error(1, err)
                                                    })
                                            }} type='button' title='Set questionnaire approval'>
                                                {item.questionnaire_approved ? <StopCircleSharp className='!h-6 !w-6 text-turquoise-400' /> : <CheckCircleSharp className='!h-6 !w-6 text-turquoise-400' />}
                                            </button>
                                        )
                                    }
                                    <a href={`/company/${account_uuid}/questionnaire#${item.questionnaire_id}`} title="Go to questionnaire">
                                        <ArrowCircleRightSharp className='!h-6 !w-6 text-turquoise-400' />
                                    </a>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
            <TableFoot>
                <TableFooterCell>
                    {
                        // End of the list.
                    }
                </TableFooterCell>
            </TableFoot>
        </Table>
        </>
    )
    */
}
/* eslint-disable */
// prettier-ignore