/* eslint-disable */
// prettier-ignore
import { useState } from "react";
import { UserProfiles } from "./comply-invite-list-users";

import MDButton from "components/MDButton";
import cysrConfig, { process } from "layouts/cysr/config";

export function ComplyInviteList({ accountUUID, data, callback }) {
    const [collapsed, setCollapsed] = useState(true)
    return (
        <div className="max-w-[85rem] py-10 lg:py-14 mx-auto">
            {/* Title */}
            <div className="flex justify-between w-full mb-10 lg:mb-14">
                <p className="mt-1 text-gray-600 dark:text-neutral-400 text-tremor-default text-tremor-content dark:text-dark-tremor-content">Manage invited people ({
                    Object.keys(data)?.length
                })</p>
                <MDButton style={cysrConfig().button_style} size="medium" className="h-8" onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? (
                        <svg
                            className="h-4 w-4"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M4 6H11L7.5 10.5L4 6Z" fill="currentColor"></path>
                        </svg>
                    ) : (
                        <svg
                            className="h-4 w-4"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M4 9H11L7.5 4.5L4 9Z" fill="currentColor"></path>
                        </svg>
                    )}
                </MDButton>
            </div>
            {collapsed ? null :
                <div className="grid grid-cols-2 gap-8 md:gap-12">
                    <UserProfiles accountUUID={accountUUID} data={data} callback={callback} />
                </div>
            }
        </div>
    )
}