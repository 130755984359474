/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import Loader from "layouts/cysr/components/Loader";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { toast } from "sonner"
// Ctrl s
import "./style.css";
import { CtrlTableAssets } from "layouts/cysr/comply/components/table-assets";
import { CtrlQuestionnairesTracker } from "layouts/cysr/comply/components/questionnaires-tracker";
import { CtrlQuestionnairesBarList } from "layouts/cysr/comply/components/questionnaires-bar-list";
import { CtrlTableQuestionnaires } from "layouts/cysr/comply/components/table-questionnaires";
import { CtrlWizardQuestionnaire } from "layouts/cysr/comply/components/wizard-questionnaire";
import { CategoryBar } from '@tremor/react';

// Ctrl e
import Card from "@mui/material/Card";
import cysrConfig from "layouts/cysr/config";
import { ComplyCredits } from './components/comply-credits';
export default function Page() {
    const accountUUID = window.location.pathname.substring(
        window.location.pathname.indexOf("/company/") + "/company/".length,
        window.location.pathname.lastIndexOf("/")
    );
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    /*
    // filter test s
    const params = new URLSearchParams(window.location.search);
    let activeTags = [];
    try {
        activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
    } catch (error) {
        console.error("Error in setting active tags:")
        console.error(error)
    }
    // filter test e
    */

    // Our State.
    const [showAddQuestionnaire, setShowAddQuestionnaire] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [views, setViews] = useState([]);
    const [frameworks, setFrameworks] = useState([]);
    const [questionnaireName, setQuestionnaireName] = useState("");
    const [questionnaires, setQuestionnaires] = useState([]);
    const [assets, setAssets] = useState([]);
    // /{v}/company/{id}/assets
    const getAssetsUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/assets`;
    // /{v}/company/{id}/questionnaires
    const getQuestionnairesUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/questionnaires`;
    // /{v}/frameworks
    const getFrameworksUrl = `${cysrConfig().ctrl_api_base_url}frameworks`;
    useEffect(() => {
        const pageTitle = "Comply";
        document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
        if (localStorage.getItem("cysr_useraccountroles")) {
            fetch(getFrameworksUrl, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                },
            })
                .then((response) => response.json())
                .then(async (frameworksResponse) => {
                    if (frameworksResponse.Error) {
                        throw (frameworksResponse.Error)
                    }
                    // getQuestionnairesUrl
                    fetch(getQuestionnairesUrl, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                        },
                    })
                        .then((response) => response.json())
                        .then(async (questionnairesResponse) => {

                            if (questionnairesResponse.Error) {
                                throw (questionnairesResponse.Error)
                            }
                            setQuestionnaires(questionnairesResponse)
                            // getAssetsUrl
                            fetch(getAssetsUrl, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                },
                            })
                                .then((response) => response.json())
                                .then(async (assetsResponse) => {

                                    if (assetsResponse.Error) {
                                        throw (assetsResponse.Error)
                                    }

                                    setAssets(assetsResponse)
                                    setViews({
                                        assets: assetsResponse,
                                        questionnaires: questionnairesResponse,
                                        frameworks: frameworksResponse
                                    });
                                })
                                .catch((err) => {
                                    console.error(0, err)
                                    toast.error(err)
                                    setViews({
                                        questionnaires: questionnairesResponse,
                                        frameworks: frameworksResponse
                                    });
                                });
                        })
                        .catch((err) => {
                            console.error(1, err)
                            toast.error(err)
                            setViews({
                                questionnaires: null,
                                frameworks: frameworksResponse
                            });
                        });
                })
                .catch((err) => {
                    console.error(2, err)
                    toast.error(err)
                    toast.error(err)
                    setViews({
                        questionnaires: null,
                        frameworks: null
                    });
                });
        } else {
            setViews({
                questionnaires: null,
                frameworks: null
            });
        }
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {views && views.frameworks ?
                        <Card>
                            <div className={darkMode ? "dark" : "light"}>
                                <div className='px-4 py-4'>
                                    <div className='flex gap-4 justify-between'>
                                        <h3 className="mt-2 text-black dark:text-white text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">Questionnaires</h3>
                                        <div className='ml-auto'>
                                            <MDButton onClick={() => setShowAddQuestionnaire(!showAddQuestionnaire)} style={cysrConfig().button_style} size="medium">
                                                {showAddQuestionnaire ? (
                                                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} />
                                                ) : (
                                                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                                                )}
                                                Add a questionnaire
                                            </MDButton>
                                        </div>
                                    </div>

                                    {showAddQuestionnaire ?
                                        <section className='p-4 mt-2 bg-zinc-100 dark:bg-zinc-900 rounded-xl border-zinc-100 dark:border-zinc-700 border-b border-t'>
                                            <h3 className="text-black dark:text-white text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">Create a questionnaire</h3>
                                            <div className='mt-4 flex gap-4 items-end w-full'>
                                                <CtrlWizardQuestionnaire options={views.frameworks} frameworks={frameworks} questionnaireName={questionnaireName} setQuestionnaireName={setQuestionnaireName} setFrameworks={setFrameworks} />
                                                <div className='ml-auto'>
                                                    <MDButton onClick={() => {
                                                        if (isLoading) { return false }
                                                        if (questionnaireName && questionnaireName.length > 0) {
                                                            if (questionnaireName && questionnaires.some(x => x.questionnaire_name === questionnaireName) === false) {
                                                                if (frameworks && frameworks.length > 0) {
                                                                    setLoading(true)
                                                                    // POST
                                                                    const postQuestionnairesUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/questionnaire`;

                                                                    fetch(postQuestionnairesUrl, {
                                                                        body: JSON.stringify({
                                                                            frameworks: frameworks,
                                                                            name: questionnaireName
                                                                        }),
                                                                        headers: {
                                                                            Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                                            "content-type": "application/json",
                                                                        },
                                                                        method: "POST"
                                                                    })
                                                                        .then((response) => response.json())
                                                                        .then(async (questionnaireResponse) => {
                                                                            if (questionnaireResponse.Error) {
                                                                                throw (questionnaireResponse.Error)
                                                                            }
                                                                            const questionnaire = {
                                                                                "questionnaire_id": questionnaireResponse.id,
                                                                                "questionnaire_name": questionnaireName,
                                                                                "questionnaire_framework": (questionnaireResponse?.frameworks
                                                                                    ? questionnaireResponse?.frameworks
                                                                                    : (
                                                                                        questionnaireResponse?.questionnaire_frameworks
                                                                                            ? questionnaireResponse?.questionnaire_frameworks
                                                                                            : frameworks
                                                                                    )).map(f => {
                                                                                        return {
                                                                                            name: f
                                                                                        }
                                                                                    }),
                                                                                "questionnaire_datecreated": "just now",
                                                                                "questionnaire_completion": questionnaireResponse?.completion ? questionnaireResponse?.completion : questionnaireResponse?.questionnaire_completion ?? {
                                                                                    answered: 0
                                                                                },
                                                                                "questionnaire_datemodified": "-",
                                                                                "questionnaire_status": "Deficient",
                                                                            }
                                                                            setQuestionnaires([...questionnaires, questionnaire])
                                                                            setLoading(false)
                                                                            toast.success("Questionnaire created")
                                                                        })
                                                                        .catch((err) => {
                                                                            console.error(1, err)
                                                                            setLoading(false)
                                                                            try {
                                                                                toast.error(JSON.stringify(err))
                                                                            } catch (error) {
                                                                                toast.error("error")
                                                                            }
                                                                        });
                                                                } else {
                                                                    toast.error("Select frameworks to create the questionnaire.")
                                                                }
                                                            } else {
                                                                toast.error("Name already in use, provide a different name to create the questionnaire.")
                                                            }
                                                        } else {
                                                            toast.error("Provide a name to create the questionnaire.")
                                                        }
                                                    }} style={cysrConfig().button_style} size="medium">
                                                        {isLoading ? (
                                                            <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
                                                        ) : (
                                                            <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().add.icon.svg }} />
                                                        )}
                                                        Create questionnaire
                                                    </MDButton>
                                                </div>
                                            </div>
                                        </section>
                                        : null
                                    }
                                    {
                                        questionnaires && questionnaires.length > 0 ? <>
                                            <div className='flex flex-col lg:flex-row gap-4'>
                                                <div className='w-full'>
                                                    <div className="flex items-center justify-between border-b border-tremor-border py-6 dark:border-dark-tremor-border">
                                                        <p className="text-base font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                                            Progress overview
                                                        </p>
                                                        {
                                                            /*
                                                        <p className="text-sm text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                                                            Controls
                                                        </p>
                                                        */
                                                        }
                                                    </div>
                                                    <div className='my-6'>
                                                        <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content flex items-center justify-between">
                                                            <span>Questionnaires answered</span>
                                                            <span>{((questionnaires.filter(q => q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total)).length / questionnaires.length) * 100).toFixed(0)}% &bull; <strong className='font-medium text-black dark:text-white'>{questionnaires.filter(q => q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total)).length} of {questionnaires.length}</strong></span>
                                                        </p>
                                                        <CategoryBar
                                                            showLabels={false}
                                                            values={[
                                                                (questionnaires.filter(q => q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total)).length / questionnaires.length) * 100,
                                                                (questionnaires.filter(q => !(q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total))).length / questionnaires.length) * 100
                                                            ]}
                                                            tooltip={`${((questionnaires.filter(q => q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total)).length / questionnaires.length) * 100).toFixed(0)}% answered`}
                                                            colors={['cyan', 'gray']}
                                                            markerValue={(questionnaires.filter(q => q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total)).length / questionnaires.length) * 100}
                                                            className="mt-3"
                                                        />

                                                    </div>
                                                    <CtrlQuestionnairesTracker questionnaires={questionnaires} />
                                                </div>
                                                {false &&
                                                    <CtrlQuestionnairesBarList questionnaires={questionnaires} />
                                                }
                                            </div>
                                            <CtrlTableQuestionnaires account_uuid={accountUUID} questionnaires={questionnaires} setQuestionnaires={setQuestionnaires} />
                                        </>
                                            : <p className='mb-2 text-left text-sm text-zinc-500 dark:text-zinc-300'>No questionnaires found.</p>
                                    }
                                </div>
                            </div>
                        </Card>
                        :
                        <Loader />
                    }
                    {
                        /*
                    }
                    {views && views.assets ?
                        <Card className='mt-4'>
                            <div className={darkMode ? "dark" : "light"}>
                                <div className='px-4 py-4'>
                                    <h3 className="mt-2 text-black dark:text-white text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">List of assets</h3>

                                    {
                                        assets && assets.length > 0 ? <>
                                            <CtrlTableAssets account_uuid={accountUUID} assets={assets} />
                                        </>
                                            : <p className='mb-2 text-left text-sm text-zinc-500 dark:text-zinc-300'>No assets found.</p>
                                    }
                                </div>
                            </div>
                        </Card>
                        :
                        <Loader />
                    }
                    {
                        */
                    }
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <ComplyCredits />
                    </Card>
                </Grid>
            </Grid>

        </DashboardLayout>
    );
}

/* eslint-disable */
// prettier-ignore
