/* eslint-disable */
// prettier-ignore

import cysrConfig from "layouts/cysr/config";

export function ComplyInviteListUsersDeleteUserCompletely(accountUUID, userEmail, callback) {
  console.warn(accountUUID, userEmail)
  // /{version}/company/{company_id}/invited-users/{inviteduser_email}/delete
  fetch(`${cysrConfig().ctrl_api_base_url}company/${accountUUID}/invited-users/${userEmail}/delete`
    , {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "DELETE"
    })
    .then((r) => {
      if (r.ok) {
        // window.location.reload();
        return r.json();
      } else {
        console.error(r);
        return r.json().then(err => {
          throw JSON.stringify(err)
        })
        return r.json()
      }
    })
    .then((response) => {
      console.log(response)
      callback()
      // setDataInvitedUsers(response)

    }
    ).catch((err) => {
      console.log(err);
      console.log("err3:", err);
      if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
        toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
      } else if (JSON.parse(err) && JSON.parse(err).error) {
        toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
      } else {
        toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
      }

    })

}


export function ComplyInviteListUsersDeleteUserResource(accountUUID, userEmail, resourceID, callback) {
  console.warn(accountUUID, userEmail, resourceID)
  // /{version}/company/{company_id}/invited-users/{inviteduser_email}/delete/{resource_id}
  fetch(`${cysrConfig().ctrl_api_base_url}company/${accountUUID}/invited-users/${userEmail}/delete/${resourceID}`
    , {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "DELETE"
    })
    .then((r) => {
      if (r.ok) {
        // window.location.reload();
        return r.json();
      } else {
        console.error(r);
        return r.json().then(err => {
          throw JSON.stringify(err)
        })
        return r.json()
      }
    })
    .then((response) => {
      console.log('deleted resource from user')
      console.log(response)
      callback()
      // setDataInvitedUsers(response)

    }
    ).catch((err) => {
      console.log(err);
      console.log("err3:", err);
      if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
        toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
      } else if (JSON.parse(err) && JSON.parse(err).error) {
        toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
      } else {
        toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
      }

    })

}
