/* eslint-disable */
// prettier-ignore

// 'use client';

import { BarChart, Card, ProgressCircle } from '@tremor/react';

function StatBarChart({ category, chartdata, className = "h-80", layout = 'vertical' }) {

    const customTooltip = (props) => {
        const { payload, active } = props;
        if (!active || !payload) return null;
        return (
            <div className="w-80 p-2 z-[999999] rounded-tremor-default text-tremor-default border bg-tremor-background shadow-tremor-dropdown border-tremor-border dark:bg-dark-tremor-background dark:shadow-dark-tremor-dropdown dark:border-dark-tremor-border">
                {payload.map((p, idx) => (
                    <div key={idx} className="flex flex-1 space-x-2.5">
                        <div
                            className={`flex w-1 flex-col bg-${p?.color}-500 rounded`}
                        />
                        <div className="space-y-1">
                            <p className="whitespace-nowrap text-tremor-content dark:text-dark-tremor-content">{p?.dataKey}</p>
                            <strong className='text-sm font-medium text-gray-900 dark:text-gray-50'>
                                {p?.payload?.tooltip_label}
                            </strong>
                            <div className='flex justify-between flex-wrap'>
                                <p className="text-gray-900 dark:text-gray-50 whitespace-nowrap text-tremor-content dark:text-dark-tremor-content">
                                    {p?.payload?.name}
                                </p>
                                <p className="font-medium tabular-nums whitespace-nowrap text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">
                                    {p?.value?.toFixed(1)}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return chartdata ?
        <BarChart
            customTooltip={customTooltip}
            showAnimation
            // showYAxis={false}
            showLegend={false}
            className={className}
            minValue={0}
            maxValue={100}
            data={chartdata}
            index="name"
            categories={[category]}
            // yAxisWidth={80}
            layout={layout}
        />
        : null
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function StatProgressCircle({ data }) {
    const value = data.value;
    const text = data.text;
    return (
        <div className="flex items-center justify-center gap-x-6">
            <ProgressCircle value={value}>
                <span className="text-sm font-medium text-gray-900 dark:text-gray-50">
                    {value?.toFixed(1)}%
                </span>
            </ProgressCircle>
            <div>
                <p className="text-sm font-medium text-gray-900 dark:text-gray-50">
                    {text}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-500">
                    {value?.toFixed(1)} of 100%
                </p>
            </div>
        </div>)
}

export function ComplyStatsOverview({ data }) {
    if (data === undefined) {
        return
    }
    const type = data && data.threat_rating ? 'Threat' : 'Risk'
    const sub_rating = type === 'Threat' ? data.threat_rating : data.risk_rating;
    return (
        <div className='flex w-full flex-col gap-2'>
            <h2 className='mb-2 w-full text-lg text-left mt-4 text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong'>
                {type} statistics
            </h2>
            <div className='w-full gap-2 flex flex-col lg:flex-row'>
                <div className='w-full relative z-10'>
                    <div className='relative z-10'>
                        {data && data.questionnaire_rating ?
                            <Card>
                                <dd className="flex items-start justify-between">
                                    <StatProgressCircle
                                        data={{
                                            text: 'Questionnaire Rating',
                                            value: data.questionnaire_rating
                                        }
                                        }
                                    />
                                    {
                                        // if need green/red top right label
                                        /*
                                    }
                                    <span
                                        className={classNames(
                                            item[1] > 80
                                                ? 'text-emerald-700 dark:text-emerald-500'
                                                : 'text-red-700 dark:text-red-500',
                                            'text-tremor-default font-medium',
                                        )}
                                    >
                                        {item[0]}
                                    </span>
                                    {
                                    */
                                    }
                                </dd>
                                {
                                    // if need some medium length text:
                                    /*
                                }
                                <dt className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                                    ......
                                </dt>
                                {
                                */
                                }
                            </Card>
                            : null}
                    </div>
                    <h3 className='w-full text-left my-4 text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong'>
                        Risk Group rating
                    </h3>
                    <div className='grid sm:grid-cols-2 gap-2'>
                        {data && data.risk_group_rating ? Object.entries(data.risk_group_rating)?.map((item) => (
                            <Card key={item[0]} className="inline-flex items-start justify-between w-auto">
                                {
                                    // <dd className="inline-flex items-start justify-between">
                                }
                                <StatProgressCircle
                                    data={{
                                        text: item[0],
                                        value: item[1]
                                    }
                                    }
                                />
                                {
                                    // if need green/red top right label
                                    /*
                                }
                                <span
                                    className={classNames(
                                        item[1] > 80
                                            ? 'text-emerald-700 dark:text-emerald-500'
                                            : 'text-red-700 dark:text-red-500',
                                        'text-tremor-default font-medium',
                                    )}
                                >
                                    {item[0]}
                                </span>
                                {
                                */
                                }
                                {
                                    // </dd>
                                }
                                {
                                    // if need some medium length text:
                                    /*
                                }
                                <dt className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                                    ......
                                </dt>
                                {
                                */
                                }
                            </Card>
                        )) : null}
                    </div>
                </div>
                <div className='w-full max-w-lg flex flex-col gap-2'>
                    <div className='relative z-50'>

                        {sub_rating ? <StatBarChart className='min-h-[560px] relative z-50' category={type} chartdata={Object.entries(sub_rating)?.map((item) => (
                            {
                                name: item[0],
                                // name: `${item[0]} ${item[1]?.name}`,
                                tooltip_label: item[1]?.name,
                                [type]: item[1]?.rating,
                            }
                        ))} /> : null}
                        {
                            // as too crowded:
                            false ?
                                <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">

                                    {sub_rating ? Object.entries(sub_rating)?.map((item) => (
                                        <Card key={item[0]}>
                                            <dd className="flex items-start justify-between">
                                                <StatProgressCircle
                                                    data={{
                                                        text: item[0],
                                                        value: item[1]
                                                    }
                                                    }
                                                />
                                                {
                                                    // if need green/red top right label
                                                    /*
                                                }
                                                <span
                                                    className={classNames(
                                                        item[1] > 80
                                                            ? 'text-emerald-700 dark:text-emerald-500'
                                                            : 'text-red-700 dark:text-red-500',
                                                        'text-tremor-default font-medium',
                                                    )}
                                                >
                                                    {item[0]}
                                                </span>
                                                {
                                                */
                                                }
                                            </dd>
                                            {
                                                // if need some medium length text:
                                                /*
                                            }
                                            <dt className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                                                ......
                                            </dt>
                                            {
                                            */
                                            }
                                        </Card>
                                    )) : null}
                                </dl> : null}
                    </div>
                </div>
            </div>

        </div>
    );
}
