/* eslint-disable */
// prettier-ignore

import {
  Button,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@tremor/react';


import "./law-css.css";

import cysrConfig from "layouts/cysr/config";
import { ArrowRightSharp, ViewSidebarSharp } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { RegulationContent } from './law-regulation-content';
import { openDB } from 'idb';

// IndexedDB configuration
const dbName = 'frameworkCacheDB';
const storeName = 'frameworkData';
const cacheTTL = 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds

async function openDatabase() {
  return openDB(dbName, 1, {
    upgrade(db) {
      db.createObjectStore(storeName, { keyPath: 'key' });
    },
  });
}

async function getCachedData(key) {
  const db = await openDatabase();
  return db.get(storeName, key);
}

async function setCachedData(key, data) {
  const db = await openDatabase();
  return db.put(storeName, { key, data, timestamp: Date.now() });
}

export function ScrolledLaw({ lawHTML, items, regulationsTitles, codeIndex, setCodeIndex, scrollToID, setScrollToID }) {
  return (
    // <div className='max-w-lg mt-6 px-4 h-full'>
    <div className='mt-6 px-4 h-full w-full'>
      <TabGroup className='pr-8' index={codeIndex} onIndexChange={(index) => {
        // on tab change s
        setCodeIndex(index)
        // setScrollToID('ZERO');
        setScrollToID('FIRST');
        // on tab change e
      }}
      >
        <TabList>
          {regulationsTitles?.map(title => (
            <Tab>{title}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {regulationsTitles?.map(r => (
            <TabPanel>
              <RegulationContent lawHTML={lawHTML} items={items} setScrollToID={setScrollToID} scrollToID={scrollToID} />
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </div>
  );
}

function parseString(inputString, prefix, suffixTag) {
  const regex = new RegExp(`${prefix}(.*?)<\/${suffixTag}>`, 'g');
  const matches = [];
  let lastIndex = 0;

  let match;
  while ((match = regex.exec(inputString)) !== null) {
    const title = match[1].trim();
    const startIndex = match.index + match[0].length;
    const endIndex = regex.lastIndex;
    const content = inputString.substring(startIndex, inputString.indexOf(prefix, startIndex)).trim();

    matches.push({
      id: title,
      title: title,
      content: content
    });
    lastIndex = endIndex;
  }

  // Capture the content after the last matched tag
  const finalContent = inputString.substring(lastIndex).trim();
  if (matches.length > 0 && finalContent) {
    matches[matches.length - 1].content = finalContent;
  }

  return matches;
}

export function LawPanel({ controlArticleFrameworks, language, isOpen, setIsOpen, MuscopeInviteAuth }) {
  /*
  "control_article": [
        {
            "article_id": "2dfbf3e1-5c39-4625-a67b-b1479d70acba",
            "article_name_l1": "ID",
            "article_name_l2": "ID.AM",
            "article_name_l3": null,
            "article_name": "ID.AM",
            "article_text": "lorem ipsum",
            "article_order": 0,
            "article_hook": null,
            "article_framework": "69b58218-cd2e-411f-a580-d98e45ea20b7"
        },
  */
  const [scrollToID, setScrollToID] = useState('FIRST')
  const [codeIndex, setCodeIndex] = useState(0)
  // const [code, setCode] = useState(codes[codeIndex])
  const [lawHTML, setLawHTML] = useState('')
  const [items, setItems] = useState(null)
  const [regulationsTitles, setRegulationsTitles] = useState()

  useMemo(() => {
    if (controlArticleFrameworks && Object.keys(controlArticleFrameworks).length > 0) {
      const frameworks = Object.entries(controlArticleFrameworks);
      const uniqueTabNames = Array.from(new Set(frameworks.map(x => x[1][0].article_framework?.framework_name ? x[1][0].article_framework?.framework_name : x[1][0].article_framework?.framework_id)));
      setRegulationsTitles(uniqueTabNames);
  
      if (frameworks && frameworks[codeIndex] && frameworks[codeIndex][0]) {

        function updateFrameworkContent(code) {
          const inputString = typeof code === 'string' ? code : '';
          let newItems = [];
        
          frameworks[codeIndex][1].sort((a, b) => {
            if (a.article_order !== b.article_order) {
              return a.article_order - b.article_order;
            }
            const hookComparison = a.article_hook.localeCompare(b.article_hook);
            if (hookComparison !== 0) {
              return hookComparison;
            }
            return a.article_name.localeCompare(b.article_name);
          }).map(article => {
            newItems.push({
              id: article.article_hook,
              title: article.article_name,
            });
          });
        
          setItems(newItems);
          setLawHTML(code);
        }

        // Cache key based on the framework ID and language
        const cacheKey = `framework_${frameworks[codeIndex][0]}_${language}`;
  
        getCachedData(cacheKey).then(cachedData => {
          if (cachedData && (Date.now() - cachedData.timestamp < cacheTTL)) {
            // Use cached data if it's still valid
            const code = cachedData.data?.document_html;
            if (code) {
              updateFrameworkContent(code);
            }
          } else {
            // Fetch fresh data if cache is not valid
            fetch(`${cysrConfig().ctrl_api_base_url}framework/${frameworks[codeIndex][0]}/document`, {
              headers: {
                ...(MuscopeInviteAuth ? { "Muscope-Invite-Auth": MuscopeInviteAuth } : { Authorization: `Bearer ${localStorage.getItem("cysr_access")}` }),
                'Accept-Language': language,
              },
              cache: "no-store" // Ensure fresh data is fetched from the server
            })
              .then(response => response.json())
              .then(getResponse => {
                const code = getResponse?.document_html;
                if (code) {
                  // Cache the data with the current timestamp
                  setCachedData(cacheKey, getResponse).then(() => {
                    updateFrameworkContent(code);
                  });
                } else {
                  setItems(null);
                  setLawHTML(JSON.stringify(getResponse));
                }
              })
              .catch(err => {
                console.error('Error:', err);
              });
          }
        }).catch(err => {
          console.error('Error accessing IndexedDB:', err);
        });
      } else if (codeIndex) {
        setCodeIndex(0);
      }
    }
  }, [codeIndex, controlArticleFrameworks]);

  return (
    <div
      style={{ maxWidth: 520 }}
      className={`ml-auto flex -mr-6 h-full top-0 ${isOpen ? "sticky w-full bg-white dark:bg-zinc-950" : "relative w-6"}`}>
      {items ?
        isOpen ? (
          <>
            <Button className="text-white p-0 !rounded-none bg-turquoise-600" onClick={() => setIsOpen(false)}>
              <ArrowRightSharp className='!h-6 !w-6' />
            </Button>
            <ScrolledLaw lawHTML={lawHTML} items={items} regulationsTitles={regulationsTitles} codeIndex={codeIndex} setCodeIndex={setCodeIndex} scrollToID={scrollToID} setScrollToID={setScrollToID} />
          </>
        ) : (
          <Button className="text-white bg-turquoise-600 absolute top-4 mt-1 h-8 right-10" onClick={() => setIsOpen(true)}>
            <div className="flex gap-2 items-center">
              <ViewSidebarSharp className='h-4 w-4' />
              View Regulations
            </div>
          </Button>
        ) :
        <Button className="text-white bg-turquoise-600 absolute top-4 mt-1 h-8 right-10" disabled>
          <div className="flex gap-2 items-center">
            {
              // <ViewSidebarSharp className='h-4 w-4' />
            }
            Regulations unavailable
          </div>
        </Button>
      }
    </div>
  );
}