/* eslint-disable */
// prettier-ignore
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

export const ComplyInviteCheckboxesControls = ({ selectedControls, setSelectedControls, controlsKeys, cSearch, controls }) => {
    const handleCheckboxChange = (value) => {
        setSelectedControls((prevSelected) => {
            if (prevSelected.includes(value)) {
                return prevSelected.filter((item) => item !== value); // Uncheck
            } else {
                return [...prevSelected, value]; // Check
            }
        });
    };

    return (
        <div className="border-tremor-border flex flex-col gap-2 dark:border-dark-tremor-border w-full max-h-[228px] overflow-scroll-y">
            <FormGroup
                className="border-tremor-border flex flex-col" placeholder="Select controls">

                {
                    controlsKeys
                        ?.filter(cc => cSearch && cSearch.length > 0 ? cc.toLowerCase()?.indexOf(cSearch?.toLowerCase()) > -1 : true)
                        .map((c) => (
                            <FormControlLabel key={c} style={{
                                marginLeft: 0,
                                marginRight: 0,
                            }} className="text-left group relative flex flex-col cursor-pointer rounded-lg bg-turquoise-50 dark:bg-white/5 pb-2 pt-1 px-2 text-black dark:text-white shadow-md transition focus:outline-none"
                                control={
                                    <Checkbox
                                        checked={selectedControls.includes(c)}
                                        onChange={() => handleCheckboxChange(c)}
                                        className="text-left my-0.5 size-6 fill-white opacity-100 transition w-full"
                                    />
                                }
                                label={
                                    <div className='flex flex-col' style={{
                                        transform: "translate(4px,-4px)"
                                    }}>
                                        <strong className='text-black dark:text-white text-base font-medium'>{c}</strong>
                                        <span style={{
                                            maxWidth: "24rem"
                                        }} className='text-left whitespace-nowrap w-full overflow-hidden text-ellipsis'>
                                            {controls[c]?.scfcontrol}
                                        </span>
                                    </div>
                                }
                            />
                        ))
                }
            </FormGroup>
        </div>
    );
};

