/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import Loader from "layouts/cysr/components/Loader";

import MDButton from "components/MDButton";

import Card from "@mui/material/Card";

import InfoButton from "layouts/cysr/components/infoButton";
// import DataTable from "layouts/cysr/components/DataTable";
// import archiveData from "layouts/cysr/archive/archiveData";
import CysrTableCompanies from "layouts/cysr/components/CysrTable/companies";
import { UserProfiles } from "layouts/cysr/comply/components/comply-invite-list-users";
import cysrConfig from "layouts/cysr/config";
import { getCompanies } from "db";
import { useMaterialUIController } from "context";

function Invites() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    localStorage.removeItem("cysr_useraccountrole_selected")
    window.location.reload()
  }
  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
  } catch (error) {
    console.error("Error in setting active tags:")
    console.error(error)
  }
  // filter test e

  // Our State.
  const [loading, setLoading] = useState(true);
  const [invites, setInvites] = useState([]);
  // Tutti gli asset di un account (/asset/) senza UUID?
  if (localStorage.getItem("cysr_useraccountroles") === null) {
    window.location.href = "/cysr/login";
  }

  useEffect(() => {
    const fetchInvites = async () => {
      const pageTitle = "Invites";
      document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;

      return fetch(`${cysrConfig().ctrl_api_base_url}all-invited-users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Error fetching invites for company ${accountUUID}: ${response.statusText}`);
          }


          return response.json().then(data => {
            setInvites(data)
            setLoading(false)
          });

        })
        .catch(error => {
          setLoading(false)
          console.error('Error fetching invites:', error);
          setInvites([]); // Optionally reset invites on error
        })
    };

    fetchInvites();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <div className={darkMode ? "dark" : "light"}>
              <MDBox py={5} px={3} fullWidth>
                <MDTypography variant="h5">Invites</MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  List of invites
                </MDTypography>
                {loading
                  ? <Loader />
                  : invites && invites.length ?
                    <MDBox pt={5} px={0} fullWidth>
                      {
                        invites?.map((y, indexY) => {
                          return y.company_id
                            ? <div className='mb-6'>
                              {indexY > 0 
                              ? <Divider />
                            : null}
                              <div>
                                <MDTypography variant="button" color="text">
                                  {y.company_name}
                                </MDTypography>
                              </div>
                              <UserProfiles accountUUID={y.company_id} data={y.company_invitedusers} callback={() => console.log(88)} />
                            </div>
                            : null
                        })
                      }
                    </MDBox>
                    : <div className='block'>
                      <MDTypography variant="caption">
                        There are no entries to show.
                      </MDTypography>
                    </div>
                }
              </MDBox>
            </div>
          </Card>
        </Grid>
      </Grid>

    </DashboardLayout>
  );
}
export default Invites;

/* eslint-disable */
// prettier-ignore
