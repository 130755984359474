/* eslint-disable */
// prettier-ignore
import MDButton from "components/MDButton";
import cysrConfig from "layouts/cysr/config";
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { toast } from "sonner"

import { DebouncedInput } from "layouts/cysr/comply/components/table-questionnaires";
import { Input } from "layouts/cysr/components/ui/input";
import {
  Button,
  Dialog,
  DialogPanel,
  Divider,
  TextInput
} from '@tremor/react';
import { useEffect, useState } from 'react';
import { CheckBoxSharp, CircleSharp, EditSharp, GraphicEqSharp, KeyboardArrowDownSharp, KeyboardArrowUpSharp, NoteAddSharp, RadioButtonCheckedSharp, RadioButtonUncheckedSharp } from '@mui/icons-material';
import {
  CloseButton,
  Popover, PopoverPanel,
  Field, PopoverButton, Radio, RadioGroup
} from '@headlessui/react'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "layouts/cysr/components/ui/form";
import { ComplyInviteList } from "./comply-invite-list";
import { ComplyInviteCheckboxesControls } from "./comply-invite-checkboxes-controls";

function isValidEmail(email) {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
}

export function ComplyInvite({ darkMode, language, accountUUID, questionnaireID, controls, className = "", children = null }) {
  const [isOpen, setIsOpen] = useState(false);
  const [inviteExpanded, setInviteExpanded] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const shortDescription = "Invite collaborators to edit controls"
  const description = "";//"Invite people to edit the Questionnaire"
  const [dataInvitedUsers, setDataInvitedUsers] = useState([]);
  const [selectedControls, setSelectedControls] = useState([]);
  const [cSearch, setCSearch] = useState('');
  const controlsKeys = Object.keys(controls);
  function invitedUsers() {
    console.warn(accountUUID)
    ///{version}/company/{company_id}/invited-users
    fetch(`${cysrConfig().ctrl_api_base_url}company/${accountUUID}/invited-users`
      , {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "GET"
      })
      .then((r) => {
        if (r.ok) {
          // window.location.reload();
          return r.json();
        } else {
          console.error(r);
          return r.json().then(err => {
            throw JSON.stringify(err)
          })
          return r.json()
        }
      })
      .then((response) => {
        // Filter out users who have the specified questionnaire ID
        const filteredResponse = Object.fromEntries(
          Object.entries(response).map(([userId, user]) => {
            // Check if the user has the specified questionnaire
            const userQuestionnaires = user.inviteduser_questionnaires;

            // Create a new object with only the relevant questionnaire if it exists
            const filteredQuestionnaires = {};
            if (userQuestionnaires.hasOwnProperty(questionnaireID)) {
              filteredQuestionnaires[questionnaireID] = userQuestionnaires[questionnaireID];
            }

            // Return user only if they have at least one relevant questionnaire
            return [userId, { inviteduser_email: user.inviteduser_email, inviteduser_questionnaires: filteredQuestionnaires }];
          }).filter(([_, user]) => Object.keys(user.inviteduser_questionnaires).length > 0) // Keep only users with relevant questionnaires
        );
        // Set the filtered data
        setDataInvitedUsers(filteredResponse);
      }
      ).catch((err) => {
        console.log(err);
        console.log("err3:", err);
        if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
          toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
        } else if (JSON.parse(err) && JSON.parse(err).error) {
          toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
        } else {
          toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
        }

      })
    // this is the Muscope-invite-Auth token
    // b6de35149db511ef95970242ac13000517664c1fe2484759a40e994cb6d40538
  }

  function onSubmit(data) {
    data?.preventDefault()
    console.warn(emailValue, data)
    if (isValidEmail(emailValue) === false) {
      toast.error('Invalid email provided')
      return false
    }
    ///{version}/questionnaire/{questionnaire_id}/invite
    fetch(`${cysrConfig().ctrl_api_base_url}questionnaire/${questionnaireID}/invite`
      , {
        body: JSON.stringify({
          invitees: [emailValue],
          controls: selectedControls,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "POST"
      })
      .then((r) => {
        if (r.ok) {
          // window.location.reload();
          return r.json();
        } else {
          console.error(r);
          return r.json().then(err => {
            throw JSON.stringify(err)
          })
          return r.json()
        }
      })
      .then((response) => {
        console.log(response)
        toast.success(`${emailValue} invited successfully`)
        invitedUsers()
      }
      ).catch((err) => {
        console.log(err);
        console.log("err3:", err);
        if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
          toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
        } else if (JSON.parse(err) && JSON.parse(err).error) {
          toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
        } else {
          toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
        }
        toast.error(`Error inviting ${emailValue}.`)
      })
    // this is the Muscope-invite-Auth token
    // b6de35149db511ef95970242ac13000517664c1fe2484759a40e994cb6d40538
  }

  // call invited users once at start
  useEffect(() => {
    invitedUsers()
  }, [])

  return (
    <div className="flex justify-center">
      <button type='button' className={className} onClick={() => setIsOpen(true)}>
        {children}
      </button>
      {/* <button type='button' className={className} onClick={() => invitedUsers()}>
        Refresh users
      </button> */}

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        static={true}
        className={`z-[999999] ${darkMode ? "dark" : "light"}`}
      >
        <DialogPanel className="max-w-lg">
          <div className='flex justify-between'>
            <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>{`Invites ${dataInvitedUsers ? `(${Object.keys(dataInvitedUsers)?.length
              })` : null
              }`}</p>
            <Button
              className="ml-auto my-0 py-1 flex text-black dark:text-white items-center"
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
          </div>
          <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">{shortDescription}</h3>
          {description && description.length > 0 ? <p className="mt-2 leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content whitespace-pre-line">
            {description}
          </p> : null}
          <ComplyInviteList accountUUID={accountUUID} data={dataInvitedUsers} callback={invitedUsers} />
          <Divider />
          <div className="flex w-full mb-4 justify-end">
            <MDButton
              style={cysrConfig().button_style} size="medium" className="h-8 gap-4"
              onClick={() => setInviteExpanded(!inviteExpanded)}>

              {inviteExpanded ? (
                <svg
                  className="h-4 w-4"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4 9H11L7.5 4.5L4 9Z" fill="currentColor"></path>
                </svg>
              ) : (
                <svg
                  className="h-4 w-4"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4 6H11L7.5 10.5L4 6Z" fill="currentColor"></path>
                </svg>
              )}
              Invite new collaborators
            </MDButton>
          </div>
          {inviteExpanded ?
            <div>
              <div className="mb-10 lg:mb-14">
                <p className="mt-1 text-gray-600 dark:text-neutral-400 text-tremor-default text-tremor-content dark:text-dark-tremor-content">Email of the person to invite</p>
              </div>
              <div className="mt-4 flex flex-col gap-4 items-center">
                <div className="w-full nolg:w-6/12 nolg:border-l nolg:border-l-turquoise-300 nolg:dark:border-l-turquoise-700 nolg:pl-4">
                  <Form>
                    <form
                      onSubmit={(data) => onSubmit(data)}
                      className="w-full flex flex-col gap-4"
                    >
                      <DebouncedInput
                        type="email"
                        value={emailValue}
                        onChange={value => setEmailValue(value)}
                        placeholder={"Insert email"}
                        className="w-36 border shadow rounded-tremor-default"
                      />
                      <div
                        // control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl onChange={(a) => console.log(a.target.value)}>
                              <Input
                                // placeholder="example@muscope.com"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <div
                        align="end"
                        className="flex flex-col gap-2 border-tremor-border dark:border-dark-tremor-border"
                      >
                        <p className='w-full text-left mt-1 text-gray-600 dark:text-neutral-400 text-tremor-default text-tremor-content dark:text-dark-tremor-content'>Controls</p>
                        <TextInput value={cSearch} onValueChange={setCSearch} className="max-w-xs" placeholder='Search controls' />
                        <div className="flex gap-4 justify-between">
                          <FormControlLabel style={{
                            marginLeft: 0,
                            marginRight: 0,
                          }}
                            className="inline-flex"
                            control={
                              <Checkbox
                                className="text-left my-0.5 size-6 fill-white opacity-100 transition"
                                checked={selectedControls?.filter(cc => cSearch && cSearch.length > 0 ? cc.toLowerCase()?.indexOf(cSearch?.toLowerCase()) > -1 : true).length === controlsKeys?.filter(cc => cSearch && cSearch.length > 0 ? cc.toLowerCase()?.indexOf(cSearch?.toLowerCase()) > -1 : true).length}
                                onChange={() => {
                                  if (selectedControls.length === controlsKeys.length) {
                                    // unselect all
                                    setSelectedControls([])
                                  } else {
                                    setSelectedControls(controlsKeys?.filter(cc => cSearch && cSearch.length > 0 ? cc.toLowerCase()?.indexOf(cSearch?.toLowerCase()) > -1 : true)
                                    )
                                  }
                                }}
                                aria-label="Select all filtered"
                              />
                            }
                            label={`Select all filtered (${controlsKeys?.filter(cc => cSearch && cSearch.length > 0 ? cc.toLowerCase()?.indexOf(cSearch?.toLowerCase()) > -1 : true)
                              ?.length})`}
                          />
                          <FormControlLabel style={{
                            marginLeft: 0,
                            marginRight: 0,
                          }}
                            className="inline-flex"
                            control={
                              <Checkbox
                                className="text-left my-0.5 size-6 fill-white opacity-100 transition"
                                checked={selectedControls.length === controlsKeys.length}
                                onChange={() => {
                                  if (selectedControls.length === controlsKeys.length) {
                                    // unselect all
                                    setSelectedControls([])
                                  } else {
                                    setSelectedControls(controlsKeys)
                                  }
                                }}
                                aria-label="Select all"
                              />
                            }
                            label={`Select all (${controlsKeys?.length})`}
                          />
                        </div>
                        <ComplyInviteCheckboxesControls
                          selectedControls={selectedControls} setSelectedControls={setSelectedControls}
                          controlsKeys={controlsKeys} cSearch={cSearch} controls={controls} />
                      </div>
                      <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 items-center">
                        <div>
                          <MDButton onClick={() => setIsOpen(false)} style={cysrConfig().button_style} size="medium">Cancel</MDButton>
                        </div>
                        <MDButton
                          className="gap-2"
                          size="medium"
                          style={cysrConfig().button_style}
                          type="submit"
                        >
                          <svg
                            className="w-4 h-4"
                            width="16"
                            height="16"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.20308 1.04312C1.00481 0.954998 0.772341 1.0048 0.627577 1.16641C0.482813 1.32802 0.458794 1.56455 0.568117 1.75196L3.92115 7.50002L0.568117 13.2481C0.458794 13.4355 0.482813 13.672 0.627577 13.8336C0.772341 13.9952 1.00481 14.045 1.20308 13.9569L14.7031 7.95693C14.8836 7.87668 15 7.69762 15 7.50002C15 7.30243 14.8836 7.12337 14.7031 7.04312L1.20308 1.04312ZM4.84553 7.10002L2.21234 2.586L13.2689 7.50002L2.21234 12.414L4.84552 7.90002H9C9.22092 7.90002 9.4 7.72094 9.4 7.50002C9.4 7.27911 9.22092 7.10002 9 7.10002H4.84553Z"
                              fill="currentColor"
                              fillRule="evenodd"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span>
                            Send
                          </span>
                        </MDButton>
                      </div>
                    </form>
                  </Form>
                </div>
              </div>
            </div>
            : null
          }
        </DialogPanel>
      </Dialog>
    </div>
  );
}