/* eslint-disable */
// prettier-ignore
// 'use client';
import { Tracker } from '@tremor/react';
import { useEffect, useState } from 'react';

export function CtrlQuestionnairesTracker({
    percentageLabel = "Questonnaires monitored",
    percentageLabel2 = "Questionnaires approved",
    questionnaires
    //    answers, c, label, legend = false
}) {
    const colorUnanswered = "gray"
    const colorsAnswers = {
        "Deficient": "gray",
        "In Place": "orange",
        "Ready for Review": "amber",
        "Monitored": "emerald",
        //   "Met": "emerald",
        //   "Not Met": "rose",
        //   "Not Applicable": "violet",
        //   "Compensating Control": "amber",
    }
    const colorsAnswers2 = {
        "Approved": "green",
        "Disapproved": "red",
    }

    function getColor(y) {
        if (y && y.objective_answer) {
            return colorsAnswers[y.objective_answer] ?? "teal"
        } else {
            console.warn(y)
        }
        return "teal"
    }

    const keyVal = "tooltip"

    const [data, setData] = useState([]
        // null
       //questionnaires.map((q)=>{
       //  return {
       //  color: colorsAnswers[q.questionnaire_status],
       //  tooltip: q.questionnaire_name && q.questionnaire_name.length > 0 ? q.questionnaire_name : q.questionnaire_id
       //}})
        /*
        [
            { color: 'orange', tooltip: 'b054ed81-9e8f-4b38-9709-42d4b788af77' },
            { color: 'emerald', tooltip: 'random ID' },
            { color: 'gray', tooltip: 'random ID' },
            { color: 'emerald', tooltip: 'random ID' },
            { color: 'emerald', tooltip: 'random ID' },
            { color: 'emerald', tooltip: 'random ID' },
            { color: 'emerald', tooltip: 'random ID' },
            { color: 'emerald', tooltip: 'random ID' },
            { color: 'amber', tooltip: '89b28dcc-1231-4417-9a10-8f329b1a5dc4' }
        ]
        */
    )
    const [data2, setData2] = useState([]
        // null
       //questionnaires.map((q)=>{
       //  return {
       //  color: q.questionnaire_approved ? "green" : "red",
       //  tooltip: q.questionnaire_name && q.questionnaire_name.length > 0 ? q.questionnaire_name : q.questionnaire_id
       //}})
        /*
        [
            { color: 'green', tooltip: 'b054ed81-9e8f-4b38-9709-42d4b788af77' },
            { color: 'red', tooltip: '89b28dcc-1231-4417-9a10-8f329b1a5dc4' },
            { color: 'red', tooltip: 'random ID' },
            { color: 'red', tooltip: 'random ID' },
            { color: 'green', tooltip: 'random ID' },
            { color: 'red', tooltip: 'random ID' },
            { color: 'red', tooltip: 'random ID' },
            { color: 'red', tooltip: 'random ID' },
            { color: 'red', tooltip: 'random ID' },
        ]
        */
    )
    useEffect(()=>{
      setData(questionnaires.map((q)=>{
        return {
        color: colorsAnswers[q.questionnaire_status],
        tooltip: q.questionnaire_name && q.questionnaire_name.length > 0 ? q.questionnaire_name : q.questionnaire_id
      }}))
      setData2(
        questionnaires.map((q)=>{
          return {
          color: q.questionnaire_approved ? "green" : "red",
          tooltip: q.questionnaire_name && q.questionnaire_name.length > 0 ? q.questionnaire_name : q.questionnaire_id
        }}))
    }, [questionnaires])
    // useEffect(() => {
    //     if (answers) {
    //         const a = JSON.parse(answers);
    //         let newData = []
    //         Object.keys(c).forEach(o => {
    //             let ob = {
    //                 color: 'gray', [keyVal]: o
    //             };
    //             if (a && a[o] && a[o].objective_answer_id) {
    //                 ob.color = getColor(a[o])
    //             } else {
    //                 ob.color = colorUnanswered
    //             }
    //             newData.push(ob)
    //         }
    //         )
    //         setData(newData)
    //         console.log(a)
    //         console.log(newData)
    //     }
    // }, [answers, c]
    // )

    // const data = [
    // { color: 'teal', tooltip: 'Answered' },
    // { color: 'gray', tooltip: 'Unanswered' },
    // { color: 'gray', tooltip: 'Maintenance' },
    // { color: 'emerald', tooltip: 'Operational' },
    // { color: 'rose', tooltip: 'Downtime' },
    // { color: 'yellow', tooltip: 'Degraded' },
    // ];

    /*
    let counts = []
    if (legend) {
      data.forEach(d => {
        let y = counts.find(x => x.key === d[keyVal])
        if (y) {
          y.count++
        } else {
          counts.push({
            key: d[keyVal],
            color: d.color,
            count: 1
          })
        }
      });
    }
    */

    return <div className='mt-6 w-full items-end flex gap-4'>
            {
                /* 
                  legend ?
                  <div className='w-full -mb-2 max-w-xs'>
                    <List>
                      {counts.map(item => (
                        <ListItem key={item.key} className="space-x-6">
                          <div className="flex items-center space-x-2.5 truncate">
                            <span
                              className={"bg-" + item.color + "-500 h-2.5 w-2.5 shrink-0 rounded-sm"}
                              aria-hidden={true}
                            />
                            <span className="truncate dark:text-dark-tremor-content-emphasis">
                              {item.key}
                            </span>
                          </div>
                          <div className="flex items-center space-x-2">
                            <span className="font-medium tabular-nums text-tremor-content-strong dark:text-dark-tremor-content-strong">
                              {item.count}
                            </span>
                            <span className="w-16 rounded-tremor-small bg-tremor-background-subtle px-1.5 py-0.5 text-tremor-label font-medium tabular-nums text-tremor-content-emphasis dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis">
                              {(item.count / data.length * 100).toFixed(2)}%
                            </span>
                          </div>
                        </ListItem>
                      ))}
                    </List>
                  </div> : null
                  */
            }
            <div className='flex gap-2 flex-col justify-end w-full max-w-xl'>
                <div className="text-tremor-default flex gap-4 items-center justify-start">
                    <span className="text-tremor-content dark:text-dark-tremor-content">{percentageLabel} <strong>{
                        // (data.filter(x => x.color !== colorUnanswered).length / data.length * 100).toFixed(2)
                    }%</strong>
                    </span>
                    {
                        Object.entries(colorsAnswers).map(([name, color]) => (
                            <div key={name} className="text-tremor-content dark:text-dark-tremor-content flex items-center space-x-2.5 truncate">
                                <span
                                    className={'bg-' + color + '-500 h-2.5 w-2.5 shrink-0 rounded-sm'}
                                    aria-hidden={true}
                                />
                                <span className="truncate dark:text-dark-tremor-content-emphasis">
                                    {name}
                                </span>
                            </div>
                        )
                        )
                    }
                </div>
                {data ? <Tracker data={data} className="mt-0 w-full h-2" /> : null}
                <div className="mt-6 text-tremor-default flex gap-4 items-center justify-start">
                    <span className="text-tremor-content dark:text-dark-tremor-content">{percentageLabel2} <strong>{
                        // (data.filter(x => x.color !== colorUnanswered).length / data.length * 100).toFixed(2)
                    }%</strong>
                    </span>
                    {
                        Object.entries(colorsAnswers2).map(([name, color]) => (
                            <div key={name} className="text-tremor-content dark:text-dark-tremor-content flex items-center space-x-2.5 truncate">
                                <span
                                    className={'bg-' + color + '-500 h-2.5 w-2.5 shrink-0 rounded-sm'}
                                    aria-hidden={true}
                                />
                                <span className="truncate dark:text-dark-tremor-content-emphasis">
                                    {name}
                                </span>
                            </div>
                        )
                        )
                    }
                </div>
                {data2 ? <Tracker data={data2} className="mt-0 w-full h-2" /> : null}
            </div>
        </div>
        
}