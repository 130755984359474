/* eslint-disable */
// prettier-ignore
import React from "react"

import { BarChart } from '@tremor/react';

const severitySortOrder = {
    "POSITIVE": 1,
    "INFO": 2,
    "LOW": 3,
    "MEDIUM": 4,
    "HIGH": 5
};

const vulnerabilitySeveritySortOrder = {
    "INFO": 2,
    "LOW": 3,
    "MEDIUM": 4,
    "HIGH": 5,
    "CRITICAL": 6
};
const cat_sort_order = {
    "Application Security": 1,
    "DNS Security": 2,
    "Email Security": 3,
    "Network Security": 4,
    "IP Reputation": 5,
    "CVE Critical": 6,
    "CVE High": 7,
    "CVE Medium": 8,
    "CVE Low": 9,
    "CVE Info": 10,
}

function convertData(inputData) {
    // Initialize the output structure
    const output = {
        labels: [],
        datasets: []
    };

    // Create a map to track unique categories and their corresponding severity descriptions
    const categoryMap = {};

    // Iterate over the input data
    for (const key in inputData) {
        const issue = inputData[key];

        // Extract category information
        issue.categories.forEach(category => {
            const categoryName = category.category_longname;
            const severityDescription = category.severity_descr;
            const assetsCount = 1;

            // Create a unique identifier for the dataset
            const datasetKey = severityDescription//`${categoryName}_${severityDescription}`;

            // Initialize the dataset if it doesn't exist
            if (!categoryMap[datasetKey]) {
                categoryMap[datasetKey] = {
                    label: severityDescription,
                    backgroundColor: getColor(severityDescription), // Function to get color based on severity
                    data: Array(output.labels.length).fill(0) // Initialize data array with zeros
                };
                output.datasets.push(categoryMap[datasetKey]);
            }

            // Update the labels if the category is new
            if (!output.labels.includes(categoryName)) {
                output.labels.push(categoryName);
                // Update the data array for the new label
                output.datasets.forEach(dataset => {
                    dataset.data.push(0); // Initialize new data point for the new label
                });
            }

            // Update the data for the corresponding category
            const categoryIndex = output.labels.indexOf(categoryName);
            categoryMap[datasetKey].data[categoryIndex] += assetsCount;
        });
    }

    // Sort labels based on severity
    output.labels.sort((a, b) => {

        return severitySortOrder[a] - severitySortOrder[b];
    });
    let chartData = []
    output.labels?.forEach((l, index) => {
        let newObj = {
            category: l,
        }
        output.datasets.forEach(d => {
            newObj[d?.label] = d.data[index]
        });
        chartData.push(newObj)
    });
    // return output;
    console.log(chartData)
    return chartData?.sort((a, b) => {
        return cat_sort_order[a.category] - cat_sort_order[b.category]
    });
}


function getColor(severity) {
    switch (severity) {
        case 'POSITIVE':
            // return "rgb(100, 150, 255)"; // More saturated blue
            return "blue"; // More saturated blue
        case 'INFO':
            // return "rgb(200, 200, 200)"; // Darker gray for better contrast
            return "gray"; // Darker gray for better contrast
        case 'LOW':
            // return "rgb(255, 230, 0)"; // Bright yellow
            return "yellow"; // Bright yellow
        case 'MEDIUM':
            // return "rgb(255, 150, 100)"; // More saturated peach
            return "orange"; // More saturated peach
        case 'HIGH':
            // return "rgb(255, 100, 100)"; // Bright red
            return "red"; // Bright red
        case 'CRITICAL':
            // return "rgb(255, 50, 50)"; // Even brighter red for critical
            return "rose"; // Even brighter red for critical
        default:
            // return "rgb(200, 200, 200)"; // Default color
            return "gray"; // Default color
    }
}

export function BarStackedHorizontal({ data, isVulnerability = false }) {
    return (
        data && data.length > 0 ? <div className="block h-80 w-full">
            <BarChart
                stack={true}
                minValue={0}
                className="h-80"
                data={convertData(data)}
                index="category"
                categories={Object.keys(isVulnerability ? vulnerabilitySeveritySortOrder : severitySortOrder)}
                colors={Object.keys(isVulnerability ? vulnerabilitySeveritySortOrder : severitySortOrder)?.map(x => getColor(x))}
                showLegend={true}
                showAnimation={true}
                layout="vertical"
            />
        </div> : null
    )
}